/* seaBlue = #0bd3d3 */
/* hotPink #f890e7 */
/* dirtyGrey #d0d0d0 */
/* fullBlack #000000 */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Source+Code+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@font-face {
  font-family: "Primary";
  src: url("./Assets/Fonts/AllRoundGothic-Book.ttf") format("truetype");
}
@font-face {
  font-family: "Secondary";
  src: url("./Assets/Fonts/batoshi.ttf") format("truetype");
}
@font-face {
  font-family: "Subtitle";
  src: url("./Assets/Fonts/Briller-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Halloween";
  src: url("./Assets/Fonts/October\ Crow.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::selection {
  background-color: hotpink;
  color: black;
}
::placeholder {
  opacity: 0.5;
}
::-webkit-scrollbar {
  width: 13px;
}
::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #f890e7;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a75c9b;
}
div::-webkit-scrollbar {
  height: 10px;
}
div::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb {
  background: #0bd3d3;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover {
  background: #0c8d8d;
}
body {
  background-image: url(./Assets/Backgrounds/Zen-TechWEBBG1.jpg);
  background-attachment: fixed;
  background-size: cover;
}
body::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(./Assets/Backgrounds/Zen-TechWEBBG1.jpg) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
a {
  text-decoration: none;
}
.font-primary {
  font-family: "Primary", monospace;
}
.font-secondary {
  font-family: "Secondary", sans-serif;
}
.font-subtitle {
  font-family: "Subtitle", sans-serif;
}
.font-halloween {
  font-family: "Halloween", sans-serif;
}
.description {
  font-size: 0.8rem;
}
.fs-1 {
  font-size: 1.2rem;
}
.fs-2 {
  font-size: 1.4rem;
}
.fs-3 {
  font-size: 1.6rem;
}
.text-center {
  text-align: center;
}
.subtitle {
  flex-basis: 100%;
  font-size: 2.7rem;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  text-transform: capitalize;
}
.secondarySubtitle {
  font-size: 2.4rem;
  text-align: center;
}
.tertiarySubtitle {
  font-size: 1.5rem;
  text-align: center;
}
.bg-seaBlue {
  background-color: #0bd3d3;
}
.bg-hotPink {
  background-color: #f890e7;
}
.bg-superWhite {
  background-color: #f3f3f3;
}
.bg-dirtyGrey {
  background-color: #d0d0d0;
}
.bg-fullBlack {
  background-color: #000000;
}
.text-seaBlue {
  color: #0bd3d3;
}
.text-hotPink {
  color: #f890e7;
}
.text-superWhite {
  color: #f3f3f3;
}
.text-dirtyGrey {
  color: #d0d0d0;
}
.text-fullBlack {
  color: #000000;
}
.ml-50 {
  margin: 0 0 0 50%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}
.floatRight {
  float: right;
}
.quickAnswer {
  font-size: 2rem;
  font-weight: bold;
  color: #0bd3d3;
}
.contactPageContainer {
  padding: 10vh 0 0 0;
}
.slideUpTransition {
  background: linear-gradient(to top, #0bd3d3 50%, transparent 50%);
  background-size: 100% 200%;
  background-position: right top;
  transition: all 0.2s;
  color: #0bd3d3;
}
.slideUpTransition:hover {
  background-position: right bottom;
  color: black;
}
.toggled-blog-nav {
  animation: swipeUp 0.4s forwards;
}
@keyframes swipeUp {
  0% {
    height: 0;
  }
  100% {
    height: 200px;
  }
}
.bigAndSmallAnimation {
  animation: bigAndSmall alternate infinite 1s;
}
@keyframes bigAndSmall {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
@media screen and (max-width: 450px) {
  .subtitle {
    font-size: 2rem;
  }
}
