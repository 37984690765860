.pageHeroContainer {
  margin: 10vh 0 0 0;
  height: 20vh;
  color: white;
  display: flex;
  padding: 0 0 0 5%;
  align-items: center;
}
.pageHeroTitle {
  font-size: 4rem;
}
.pageHeroTitleLong {
  font-size: 3rem;
}
@media (max-width: 500px) {
  .pageHeroTitle {
    font-size: 2rem;
  }
  .pageHeroTitleLong {
    font-size: 1.7rem;
  }
}
