.missionWrapper {
  display: flex;
  flex-wrap: wrap;
}
.missionDescription {
  flex-basis: 100%;
  font-size: 1.6rem;
  color: #f890e7;
  text-align: center;
  margin: 30px 0;
}
.missionOneHalf {
  display: flex;
  margin: 30px 0;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
}
.missionImg {
  width: 70%;
  min-width: 300px;
  border-radius: 20px;
}
.missionPlan {
  font-size: 1.8rem;
  color: #e8e8e8;
  width: 500px;
  text-align: center;
}
@media (max-width: 1000px) {
  .missionPlan {
    max-width: 350px;
  }
}
@media (max-width: 700px) {
  .missionOneHalf {
    margin: 0 auto;
  }
}
@media (max-width: 450px) {
  .missionPlan {
    max-width: 300px;
  }
}
