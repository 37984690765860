.packageFormDescription {
  width: 50%;
  font-size: 1.2rem;
  color: #f890e7;
  text-align: center;
  margin: 20px auto;
}
.packageFormWrapper {
  width: 300px;
  background-color: #1c6d7a;
  margin: 10vh auto 5vh auto;
  display: flex;
  border-radius: 20px;
  justify-content: center;
}
.formWrapper {
  width: 80%;
  height: fit-content;
  padding: 20px 0;
  margin: 10px 0 10px 0;
  color: white;
}
.packageFormInput {
  width: 100%;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 20px;
  margin: 5px 0 20px 0;
  border: 1px solid #e9e9e9;
}
.packageFormMessage {
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 5px;
  resize: none;
}
.packageFormMessage::-webkit-scrollbar {
  width: 0;
}
.btnWrapper {
  display: flex;
  justify-content: center;
}
.packageFormBtn {
  width: 50%;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  box-shadow: 1px 1px 5px #3d3d3d;
}
.packageFormBtn:active {
  background-color: #e9e9e9;
  box-shadow: none;
}
