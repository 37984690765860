.aboutPageDescription {
  width: 60%;
  margin: 30px auto;
  font-size: 1.6rem;
  color: #f890e7;
}
.whyAboutPageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.leftSideAbout {
  width: 500px;
  padding: 40px;
  display: flex;
  align-items: center;
}
.leftSideAboutDescription {
  font-size: 1.3rem;
  color: #e8e8e8;
  text-align: center;
}
.rightSideAbout {
  width: 300px;
}
.rightSideImg {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: 4px solid white;
  border-radius: 10px;
}
.aboutWhyUsContainer {
  padding: 10vh 0;
  background-position: 40% center;
}
.aboutWhyUsListWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.aboutWhyUsListItem {
  font-size: 1.4rem;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 270px;
  padding: 30px 20px;
  margin: 20px 0;
  list-style: none;
  color: #0bd3d3;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: 2px 2px 10px #f890e7;
  border-radius: 20px;
}
.orderNumber {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #f890e7;
  margin: 20px 0 0 0;
}
.pageCtaContainer {
  padding: 10vh 0;
  background-color: #3f2539;
}
.pageCta {
  width: fit-content;
  margin: 30px auto;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 1.4rem;
  color: #212121;
  font-weight: bold;
  background: linear-gradient(to right, #0bd3d3 50%, #f890e7 50%);
  background-size: 200% 100%;
  background-position: bottom right;
  transition: all 0.2s;
}
.pageCta:hover {
  background-position: bottom left;
  box-shadow: 5px 5px 10px #f890e7;
}
@media (max-width: 768px) {
  .aboutWhyUsContainer {
    background-attachment: scroll !important;
    background-position: 45% center !important;
  }
}
@media (max-width: 500px) {
  .aboutPageDescription {
    font-size: 1.3rem;
  }
  .quickAnswer {
    font-size: 1.3rem;
  }
  .aboutWhyUsListItem {
    background-color: #212121f3;
    height: 300px;
  }
}
