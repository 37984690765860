.packageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 100px;
  margin-bottom: 50px;
}
.packageWrapper {
  width: 300px;
  height: 670px;
  border: 1px solid #e8e8e8;
  background-color: #e7e7e7;
  border-radius: 20px;
  cursor: default;
}
.packageTitle {
  font-size: 2.4rem;
  text-align: center;
  margin: 10px 0 0 0;
  color: #0bd3d3;
  font-weight: bold;
}
.packageDescription {
  font-size: 1rem;
  padding: 0 13px;
  text-align: center;
}
.packagePrice {
  font-size: 2rem;
  text-align: center;
  color: #0bd3d3;
  font-weight: bold;
}
.packageListWrapper {
  list-style-type: disc;
  width: 85%;
  margin: 0 auto;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}
.packageListItem {
  width: 100%;
}
@media (max-width: 500px) {
  .packageContainer {
    gap: 5px;
  }
}
