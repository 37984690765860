.heroImg {
  height: 100vh;
  background: linear-gradient(to bottom right, #3b3b3b65, #35353584), url(../../../Assets/Backgrounds/zentechBGHero.jpeg);
  background-size: cover;
  background-blend-mode: hard-light;
  background-position: bottom;
  background-attachment: fixed;
  position: relative;
}
.heroCtaContainer {
  width: 60%;
  height: fit-content;
  background-color: rgba(31, 31, 31, 0.667);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 25vh auto;
  border-radius: 20px;
}
.heroTitle {
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
}
.heroDescription {
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: #0bd3d3;
}
.scrollForMore {
  position: absolute;
  width: fit-content;
  text-align: center;
  font-size: 1.3rem;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  animation: keepScrolling 01.3s linear alternate infinite;
}
@keyframes keepScrolling {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
@media (max-width: 768px) {
  .heroCtaContainer {
    width: 80%;
    padding: 5px;
    margin: 30vh auto;
    background-color: rgba(31, 31, 31, 0.742);
  }
  .heroTitle {
    font-size: 1.8rem;
  }
  .heroDescription {
    font-size: 1.4rem;
  }
  .heroImg,
  .heroImg::before {
    background-attachment: scroll;
  }
  .scrollForMore {
    font-size: 1rem;
  }
}
@media (max-width: 400px) {
  .heroCtaContainer {
    width: 80%;
    padding: 5px;
    margin: 30vh auto;
    background-color: rgba(31, 31, 31, 0.742);
  }
  .heroTitle {
    font-size: 1.3rem;
  }
  .heroDescription {
    font-size: 1rem;
  }
}
@media (max-width: 320px) {
  .heroCtaContainer {
    width: 90%;
    padding: 5px;
    margin: 30vh auto;
    background-color: rgba(31, 31, 31, 0.742);
  }
  .heroTitle {
    font-size: 1.3rem;
  }
  .heroDescription {
    font-size: 1rem;
  }
}
