.navbar {
  width: 100%;
  height: 10vh;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 999;
  background-color: #323232c4;
}
.navbarMobile {
  width: 100%;
  height: 10vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 999;
  background-color: #c3c3c314;
}

.hamburger {
  width: 30px;
  height: 30px;
  background: url(../../Assets/hamburgerMenuBlack.png);
  margin: 0 20px 0 0;
  background-size: cover;
}
.hamburgerX {
  width: 30px;
  height: 30px;
  background: url(../../Assets/hamburgerMenuXBlack.png);
  margin: 0 20px 0 0;
  background-size: cover;
}
.logoWrapper {
  margin: 0 150px 0 30px;
}
.logo {
  width: 60px;
  cursor: pointer;
}
.nav-container {
  width: 60%;
  display: flex;
  margin: 0 auto;
  gap: 60px;
  justify-content: center;
}
.navItem {
  list-style-type: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #f3f3f3;
  transition: 0.2s all;
  cursor: pointer;
}
.navItem:hover {
  text-shadow: 1px 2px 2px #f890e7;
}
.navItemToggle {
  list-style-type: none;
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  color: #f3f3f3;
  transition: 0.2s all;
  cursor: pointer;
}
.navItemToggled {
  list-style-type: none;
  background-color: #000000e9;
  font-size: 2rem;
  width: 300px;
  margin: 0 auto;
  position: absolute;
  cursor: pointer;
  top: 10vh;
  left: 20%;
  overflow: hidden;
  right: 0;
  text-align: center;
  animation: swipeDown 0.4s forwards;
}
@keyframes swipeDown {
  0% {
    height: 0px;
  }
  100% {
    height: 140px;
  }
}
.navItemToggled li {
  color: #f890e7;
}
.navItemToggled li:hover {
  background-color: #0bd3d3;
}
.navContact {
  width: 20%;
  margin: 0 auto;
  font-size: 1.5rem;
  color: #f3f3f3;
  font-weight: bold;
}
.navCta {
  color: #f3f3f3;
  transition: all 0.2s;
}
.navCta:hover {
  text-shadow: 1px 2px 2px #0bd3d3;
}
.nav-containerMobile {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100vw;
  top: 10vh;
  left: 0;
  text-align: center;
  z-index: 9;
  animation: menuDrop 0.1s linear;
}
@keyframes menuDrop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.navItemMobile {
  font-size: 1.2rem;
  color: #f890e7;
  transition: all 0.2s;
  padding: 5px 0;
}
