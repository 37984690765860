.meetOurTeamContainer {
  margin: 5vh 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 100px;
}
.profileContainer {
  width: 300px;
  height: 670px;
  padding: 15px;
  margin-bottom: 5vh;
  text-align: center;
  background: linear-gradient(to top, #e8e8e8 5%, #182f33 85%);
  border-radius: 30px;
}
.profilePicWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
.profilePic {
  width: 200px;
  height: 300px;
  border-radius: 0 40% 0 40%;
  object-fit: cover;
  filter: grayscale(1);
}
.profileBadge {
  width: 50px;
  height: 50px;
  position: absolute;
  object-fit: cover;
  bottom: -20px;
  right: 20%;
  border-radius: 50%;
  object-position: center;
  border: 4px solid #ededed;
  background-color: #0bd3d3;
}

.profileFirstName {
  position: absolute;
  top: 50%;
  left: 15%;
  font-size: 3rem;
  color: #6acbcb;
  text-shadow: 3px 2px 1px #182f33;
  font-style: italic;
}
.profileLastName {
  position: absolute;
  top: 65%;
  left: 15%;
  font-size: 1.5rem;
  color: #ff00d4;
  text-shadow: 1px 1px 2px #182f33;
  font-style: italic;
}
.profileRole {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px 0;
  color: #f890e7;
}
.profileBio {
  font-size: 1rem;
}
.zenTechLogo {
  width: 420px;
  height: 380px;
  margin: auto 0;
  object-fit: cover;
}
.partnerRedirect {
  width: fit-content;
  font-size: 1.4rem;
  color: #0bd3d3;
  background-color: #090f10;
  padding: 5px 10px;
  margin: 5px auto;
  border-radius: 10px;
  transition: all 0.2s;
}
.partnerRedirect:hover {
  background-color: #f890e7;
  color: #182f33;
}
@media (max-width: 450px) {
  .zenTechLogo {
    width: 320px;
    height: 290px;
  }
}
