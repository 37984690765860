.serviceContainer {
  padding: 5vh 0;
}
.serviceWrapperLeft {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  height: 250px;
  margin: 0 0 50px 25%;
  transition: all 0.4s;
  padding: 0 15px;
  border-radius: 10px;
}
.serviceDescription {
  width: 50%;
}
.serviceWrapperLeft:hover {
  box-shadow: 5px 5px 10px #0bd3d3;
}
.serviceWrapperRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  height: 250px;
  margin: 0 0 50px 25%;
  transition: all 0.4s;
  padding: 0 15px;
  border-radius: 10px;
}
.serviceWrapperRight:hover {
  box-shadow: 5px 5px 10px #0bd3d3;
}
.serviceItem {
  width: 50%;
  margin-top: 0;
  margin-bottom: 0;
}
.serviceDescriptionWrapper {
  display: flex;
  width: 100%;
}
.descriptionImg {
  height: 120px;
  object-fit: cover;
  margin: 30px auto;
}
@media screen and (max-width: 700px) {
  .descriptionImg {
    display: none;
  }
  .serviceItem {
    width: 100%;
  }
  .serviceDescription {
    width: 100%;
    text-align: center;
  }
  .serviceWrapperRight {
    width: 90%;
    margin: 30px auto;
    box-shadow: 5px 5px 10px #0bd3d3;
  }
  .serviceWrapperLeft {
    width: 90%;
    margin: 30px auto;
    box-shadow: 5px 5px 10px #0bd3d3;
  }
}
