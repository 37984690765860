.titleAnimation {
  opacity: 0;
  animation: titleAnimation 0.2s forwards;
}
@keyframes titleAnimation {
  0% {
    opacity: 0;
    width: 0%;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
